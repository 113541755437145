import React from "react"
import WhiteBox from "components/member/profile/whiteBox"

const WhiteBoxContainer = props => {
  return (
    <WhiteBox subject={props.subject}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          margin: "0px 80px",
        }}
      >
        {props.children}
      </div>
    </WhiteBox>
  )
}

export default WhiteBoxContainer

import React from "react"
import * as styles from "components/member/profile/register.module.css"

const Input = ({ textname, error, name, saveInfo_, value, inputStyle }) => {
  return (
    <>
      <input
        className={styles.input_}
        placeholder={textname}
        name={name}
        onChange={e => {
          saveInfo_(name, e.target.value)
        }}
        value={value}
        // value2={value}
        style={inputStyle}
      ></input>
    </>
  )
}

export default Input

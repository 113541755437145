import React, { useCallback, useState, useEffect, useRef } from "react"
import Register from "components/member/profile/register"
import SquareboxRegister from "components/member/profile/squareboxRegister"
import * as styles from "pages/member/profile/home.module.css"
import * as styles2 from "components/member/profile/register.module.css"

import { profiles, topics } from "utils/data.js"

import { navigate } from "gatsby"

const RegisterProfileContainer = ({
  openBox_,
  saveInfo_,
  profile,
  checkprofile_,
  title,
  save_,
  verifyNum_,
  successSubject_,
  subjectArray,
  setIsClicked,
  setClickedKey,
}) => {
  let selectedText
  let selectedPhoto
  let selectedSubnum

  //아이콘 선택하는 모달
  const profile_ = () => {
    openBox_(
      [<span key="">프로필 이미지</span>, "를 선택해 주세요."],
      <>
        {Array.from(profiles).map(topic =>
          topic[1] !== null ? (
            <SquareboxRegister
              key={topic[0]}
              className={styles.parentcheckBoxImg}
              src={topic[1]}
              subclassName={styles.checkBoxImg}
              imgclassName={styles.imgclassName}
              onclick={setProfile_}
              subject={topic[0]}
            />
          ) : (
            <SquareboxRegister key={topic[0]} className={styles.graycheckbox} />
          )
        )}
      </>
    )
  }

  const selectSubject_ = (src, text, boolean, subject) => {
    selectedText = text
    selectedPhoto = src
    selectedSubnum = subject
  }

  //관심주제 선택하는 모달
  const interested_ = () => {
    openBox_(
      [
        "보고 싶은 ",
        <span key="">관심 주제</span>,
        "를 최대 5개까지 선택하시면, 피치서가가 스토리를 추천해 드릴게요.",
      ],
      <>
        {Array.from(topics).map(([topic_no, contents]) => (
          <SquareboxRegister
            key={topic_no}
            className={styles.parentcheckBoxImg}
            src={contents["img"]}
            subclassName={styles.checkBoxImg}
            imgclassName={styles.imgclassName}
            text={contents["contents"]}
            onclick={selectSubject_}
            boolean={subjectArray}
            subject={topic_no}
            classId={contents["contents"]}
            setIsClicked={setIsClicked}
          />
        ))}

        <div className={`${styles.ptagforIcon} ${styles2.divchild}`}>
          <button
            className={styles.subjectBtn}
            onClick={() =>
              successSubject_(selectedText, selectedPhoto, selectedSubnum)
            }
          >
            선택완료
          </button>
          <div>
            <p>선택한 관심주제는 프로필 관리에서 수정할 수 있어요.</p>
          </div>
        </div>
      </>
    )
  }

  const setProfile_ = (index, text, boolean, subject) => {
    openBox_(
      [<span key="">프로필 이미지</span>, "를 등록하시겠습니까?"],
      <div className={styles.selectProfileicon}>
        <SquareboxRegister imgclassName={styles.profilebox} src={index} />
        <div className={styles.BtnContainer}>
          <div
            className={styles.confirmBtn}
            onClick={() => {
              saveInfo_("profile", index, "profileNum", subject)
            }}
          >
            네
          </div>
          <div
            className={styles.cancelBtn}
            onClick={() => {
              profile_()
            }}
          >
            아니요
          </div>
        </div>
        <p>프로필 아이콘은 언제든지 프로필에서 수정할 수 있습니다.</p>
      </div>
    )
  }

  const savev_ = () => {
    const i = "필수 항목입니다."

    document.getElementsByClassName("errMsg")[1].textContent =
      document.getElementsByName("name")[0].value === "" ? i : ""
    document.getElementsByClassName("errMsg")[2].textContent =
      document.getElementsByName("phoneNum")[0].value === "" ? i : ""

    if (title != "프로필 수정") {
      document.getElementsByClassName("errMsg")[3].textContent =
        document.getElementsByName("phoneAuth")[0].value === "" ? i : ""
    } else {
      document.getElementsByClassName("errMsg")[3].textContent = ""
    }

    document.getElementsByClassName("errMsg")[4].textContent =
      document.getElementsByName("email")[0].value === "" ? i : ""

    if (checkprofile_("profile") === "") {
      document.getElementsByClassName("errMsg")[0].textContent = i
    } else {
      document.getElementsByClassName("errMsg")[0].textContent = ""
    }

    if (checkprofile_("subject").length == 0) {
      document.getElementsByClassName("errMsg")[5].textContent = i
    } else {
      document.getElementsByClassName("errMsg")[5].textContent = ""
    }

    for (let i = 0; i < 5; i++) {
      if (document.getElementsByClassName("errMsg")[i].textContent !== "") {
        return
      }
    }

    save_()
  }

  const cancel_ = () => {
    navigate("/member/profile/home")
  }

  return (
    <Register
      profile_={profile_}
      interested_={interested_}
      profile={profile}
      save_={savev_}
      cancel_={cancel_}
      title={title}
      saveInfo_={saveInfo_}
      verifyNum_={verifyNum_}
      setClickedKey={setClickedKey}
    ></Register>
  )
}

export default RegisterProfileContainer

import React from "react"
import * as styles from "components/member/profile/register.module.css"

const WhiteBox = props => {
  return (
    <div>
      <div
        className={`${styles.box} ${styles.divchild}`}
        style={{ fontWeight: "700", width: "1200px" }}
      >
        {props.subject}

        <hr />
        {props.children}
      </div>
    </div>
  )
}

export default WhiteBox

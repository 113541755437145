import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react"
import RegisterProfileContainer from "containers/member/profile/registerProfileContainer"
import WhiteBoxContainer from "containers/member/profile/whiteBoxContainer"
import * as styles from "pages/member/profile/home.module.css"
import Logo from "components/tools/logo.js"
import api from "apis"
import { navigate } from "gatsby"
import { emailReg } from "utils/memberUtils"
import modalContext from "context/modalContext"
import TwoBtnModal from "components/tools/modalComponents/twoBtnModal"

const RegisterProfile = () => {
  const [state, setState] = useState(null)
  const [subject, setSubject] = useState(null)
  const [subjectArray, setSubjectArray] = useState([])
  const [photoArray, setPhotoArray] = useState([])
  const [subnumarray, setSubnumArray] = useState([])
  const isClicked = useRef()
  const clickedKey = useRef()
  const { setmodalOpen, setModalMain } = useContext(modalContext)

  const [profile, setProfile] = useState({
    profile: "",
    profileNum: 0,
    name: "",
    phoneNum: "",
    phoneAuth: "",
    email: "",
    subject: [],
    subjectPhoto: [],
    subnumarray: [],
  })
  const [T, setT] = useState(false)
  const turnT = () => setT(T => !T)

  useEffect(() => {
    saveInfo_(
      "subject",
      subjectArray,
      "subjectPhoto",
      photoArray,
      "subnumarray",
      subnumarray
    )
    console.log(subjectArray)
  }, [subjectArray, photoArray, subnumarray, T])

  const openBox_ = (sub, check) => {
    setSubject(sub)
    setState(check)
  }

  const setClickedKey = key => {
    console.log(key)
    clickedKey.current = key
  }

  const setIsClicked = target => {
    const prevClicked = isClicked.current ?? target
    isClicked.current = target
    return prevClicked
  }

  const successSubject_ = (text, photo, subnum) => {
    if (subjectArray.length < 5) {
      text === undefined
        ? setSubjectArray(prev => [...prev])
        : setSubjectArray(prev => [...prev, text])
      photo === undefined
        ? setPhotoArray(prev => [...prev])
        : setPhotoArray(prev => [...prev, photo])
      subnum === undefined
        ? setSubnumArray(prev => [...prev])
        : setSubnumArray(prev => [...prev, subnum])
    } else {
      subjectArray[clickedKey.current] = text
      photoArray[clickedKey.current] = photo
      subnumarray[clickedKey.current] = subnum
      console.log(subjectArray)
      console.log(photoArray)
      console.log(subnumarray)
      turnT()
    }
  }

  const saveInfo_ = useCallback(
    (sub, info, sub1, info1, sub2, info2) => {
      setState(null)
      setProfile({ ...profile, [sub1]: info1, [sub]: info, [sub2]: info2 })
    },
    [profile]
  )

  const checkprofile_ = sub => {
    return profile[sub]
  }

  //핸드폰 인증하기
  const verifyNum_ = async () => {
    console.log(profile["phoneNum"])

    if (document.getElementsByName("phoneNum")[0].value === "") {
      document.getElementsByClassName("errMsg")[2].textContent =
        "필수 항목입니다."
      return
    }

    const result = await api.getMobileConfirm({
      body: { phone_number: profile["phoneNum"] },
    })

    if (result == undefined) {
      document.getElementsByClassName("errMsg")[2].textContent =
        "숫자만을 입력해주세요."
      return
    }

    if (result) {
      phone = result
      alert("인증번호가 발송되었습니다.")
    }

    console.log(result)
  }
  const cancel_ = () => {
    navigate("/member/profile/home")
  }

  const save_ = async () => {
    console.log(profile)

    if (!emailReg(profile["email"])) {
      alert("올바른 이메일 주소를 입력해 주세요.")
      return
    }

    const result = await api.mobileConfirm({
      body: {
        phone_code: profile["phoneAuth"], // code -> phone_code로 수정
        phone_uuid: phone["uuid"], // number -> phone_uuid로 수정
      },
    })

    if (result?.uuid) {
      phone = result
    } else {
      alert("인증번호를 정확하게 입력해 주세요.")
      return
    }

    console.log(profile["subnumarray"].join("#"))
    console.log(result?.uuid)

    if (!profile["subnumarray"].join("#")) {
      alert("관심주제 선택해주세요.")
      return
    }

    if (!profile["subnumarray"].join("#")) {
      alert("관심주제 선택해주세요.")
      return
    }

    console.log(result?.uuid)

    if (result?.uuid) {
      setmodalOpen(true)
      setModalMain(
        <TwoBtnModal
          onConfirm={async () => {
            const resultFinal = await api.registerProfile({
              body: {
                // body 내용 변경
                profile_name: profile["name"],
                profile_icon: profile["profileNum"],
                phone_uuid: phone["uuid"], // 수정 완료
                profile_email: profile["email"],
                profile_topics: profile["subnumarray"].join("#"),
              },
            })

            if (resultFinal.desc !== "success") {
              alert("프로필 생성에 실패하였습니다.")
              return
            }

            setmodalOpen(false)
            navigate("/front")
          }}
          close={() => {
            console.log("아니요 클릭")
            setmodalOpen(false)
          }}
          size={{
            w: 540,
            h: 240,
          }}
          title="입력한 정보로 프로필을 생성하시겠어요?"
          confirm="네"
          cancel="아니요"
          className={{
            btn_1: styles.greenconfirmBtn,
            btn_2: styles.greencancelBtn,
          }}
        >
          {/* <div>
              <p
                style={{
                  fontSize: "16px",
                  color: "#7C7C7C",
                  textAlignLast: "center",
                }}
              >
                다른 기기에서 피치서가를 실행중인 사용자가 회원님이 아니라면
                <br />
                <span style={{ color: "#E95C63" }}>“기기변경”</span>를 클릭해주세요.
              </p>
            </div> */}
        </TwoBtnModal>
      )
      // alert("프로필 저장 성공 ")
      // navigate("/member/profile/home")
    } else {
      // alert("프로필 저장 실패 ")
    }

    return
  }

  let txt = ""
  if (subject) {
    txt = subject[subject.length - 1]
  }
  return (
    <div
      className={styles.home}
      style={{
        minHeight:
          txt === "를 선택해 주세요."
            ? "1800px"
            : state &&
              txt ===
                "를 최대 5개까지 선택하시면, 피치서가가 스토리를 추천해 드릴게요."
            ? "1400px"
            : "900px",
      }}
    >
      <div className={`${styles.divparent} ${styles.divall}`}>
        <div className={styles.divlogo}>
          <div onClick={() => navigate(`/login`)}>
            <Logo w={188} h={41} white={true} navCancel={true} />
          </div>
        </div>
      </div>

      <div className={`${styles.divparent} ${styles.divparentcenter}`}>
        {state ? (
          <WhiteBoxContainer subject={subject}> {state} </WhiteBoxContainer>
        ) : (
          <RegisterProfileContainer
            title={"프로필 등록"}
            openBox_={openBox_}
            saveInfo_={saveInfo_}
            profile={profile}
            checkprofile_={checkprofile_}
            save_={save_}
            verifyNum_={verifyNum_}
            successSubject_={successSubject_}
            subjectArray={subjectArray}
            setIsClicked={setIsClicked}
            setClickedKey={setClickedKey}
          />
        )}
      </div>
    </div>
  )
}

export default RegisterProfile

export let phone = {
  number: "",
  code: "",
  timestamp: "",
  check: false,
}

import React, { Fragment } from "react"
import * as styles from "components/member/profile/register.module.css"
import SquareboxRegister from "components/member/profile/squareboxRegister"
import whiteplusbtn from "images/whiteplusbtn.png"
import Input from "components/member/profile/input"

const Register = ({
  profile_,
  interested_,
  profile,
  save_,
  cancel_,
  title,
  saveInfo_,
  verifyNum_,
  setClickedKey,
}) => {
  const subjectA = [[], [], [], [], []]
  return (
    <>
      <div
        className={`${styles.register} ${styles.divchild}`}
        style={{
          height: "90vh",
          color: "white",
        }}
      >
        <p>{title}</p>
        <hr style={{ margin: "20px 0" }} />
        <div>
          <div>
            {profile["profile"] ? (
              <SquareboxRegister
                imgclassName={styles.selectProfileicon}
                src={profile["profile"]}
                onclick={profile_}
                className={styles.parentcheckBoxImg2}
                subclassName={styles.checkBoxImg2}
                text={"수정"}
              />
            ) : title === "프로필 수정" ? (
              <SquareboxRegister
                className={styles.addregister1}
                src={whiteplusbtn}
                onclick={profile_}
              />
            ) : (
              <SquareboxRegister
                className={styles.addregister1}
                src={whiteplusbtn}
                onclick={profile_}
              />
            )}
            <div className="errMsg" style={{ marginTop: "8px" }} />
          </div>

          <div className={styles.register2}>
            <Input
              textname="이름"
              name="name"
              saveInfo_={saveInfo_}
              value={profile["name"]}
              inputStyle={{ fontSize: "14px", fontWeight: "400" }}
            />
            <div
              className="errMsg"
              style={{ marginLeft: "20px", paddingBottom: "10px" }}
            />
            <div>
              <Input
                textname="휴대전화 번호"
                name="phoneNum"
                saveInfo_={saveInfo_}
                value={profile["phoneNum"]}
                inputStyle={{ fontSize: "14px", fontWeight: "400" }}
              />
              <button
                style={{
                  marginLeft: "4px",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
                onClick={verifyNum_}
              >
                인증하기
              </button>
            </div>
            <div
              className="errMsg"
              style={{ marginLeft: "20px", paddingBottom: "10px" }}
            />

            <Input
              textname="인증번호 입력"
              name="phoneAuth"
              saveInfo_={saveInfo_}
              value={profile["phoneAuth"]}
              inputStyle={{ fontSize: "14px", fontWeight: "400" }}
            />
            <div
              className="errMsg"
              style={{ marginLeft: "20px", paddingBottom: "10px" }}
            />
            <Input
              textname="이메일 주소"
              name="email"
              saveInfo_={saveInfo_}
              value={profile["email"]}
              inputStyle={{ fontSize: "14px", fontWeight: "400" }}
            />
            <div
              className="errMsg"
              style={{ marginLeft: "20px", paddingBottom: "10px" }}
            />
          </div>
        </div>

        <hr style={{ margin: "40px 0" }} />

        <div>
          <p style={{ color: "white", fontSize: "26px" }}>관심주제</p>
          <div className={styles.registersubject}>
            <div>
              {subjectA.map((icon, index) => {
                return (
                  <Fragment key={index}>
                    {profile["subject"][index] === undefined ? (
                      <SquareboxRegister
                        index={index}
                        src={whiteplusbtn}
                        className={styles.subjectbox}
                        onclick={interested_}
                        imgclassName={styles.imgclassName}
                        setClickedKey={setClickedKey}
                      />
                    ) : (
                      <>
                        <SquareboxRegister
                          index={index}
                          src={profile["subjectPhoto"][index]}
                          className={styles.parentcheckBoxImg}
                          subclassName={styles.checkBoxImg}
                          imgclassName={styles.imgclassName}
                          onclick={interested_}
                          text={profile["subject"][index]}
                          setClickedKey={setClickedKey}
                        />
                      </>
                    )}
                  </Fragment>
                )
              })}
            </div>
          </div>
        </div>
        <div
          className="errMsg"
          style={{ float: "right", marginTop: "15px", marginRight: "35px" }}
        ></div>

        <div style={{ marginTop: "150px" }}>
          <button className={styles.saveBtn} onClick={save_}>
            저장
          </button>
          <button className={styles.unsaveBtn} onClick={cancel_}>
            취소
          </button>
        </div>
      </div>
    </>
  )
}

export default Register
